<template>
  <a-modal :maskClosable="false" :title="handle=='add'?'新增':'编辑'" style="top: 8px;" :width="800" v-model="visible" @ok="toSubmit" @cancel="toInit">
    <a-form-model ref="form" :model="form" :rules="rules" layout="horizontal" :label-col="{span:4}" :wrapper-col="{span:20}">
      <a-form-model-item label="上级区域" prop="logAccount">
        <PCD ref="pcd" :provinceId.sync="form.provinceId" :cityId.sync="form.cityId" :districtId.sync="form.districtId" ></PCD>
      </a-form-model-item>
      <a-form-model-item label="层级" prop="level">
        <a-select v-model="form.level" @select="getLevel">
          <a-select-option v-if="!form.provinceId" :value="0">省</a-select-option>
          <a-select-option v-if="form.provinceId&&!form.cityId" :value="1">市</a-select-option>
          <a-select-option v-if="form.cityId&&!form.districtId" :value="2">区/县</a-select-option>
          <a-select-option v-if="form.districtId" :value="3">板块</a-select-option>
          <a-select-option v-if="form.cityId&&!form.districtId" :value="4">地铁线</a-select-option>
          <a-select-option v-if="form.cityId&&!form.districtId" :value="5">地铁站</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item v-if="form.level==5" label="地铁线">
        <a-select v-model="form.subwayline" :options="subwayOptions"></a-select>
      </a-form-model-item>
      <a-form-model-item label="名称" prop="name" :rules="{required:true,message:'请输入名称',trigger:'blur'}">
        <a-input v-model.trim="form.name"></a-input>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
  export default {
    data() {
      return {
        subwayOptions: [],
        visible: false,
        rules: {},
        handle:"add",
        form:{}
      }
    },
    methods: {
      showInfo(row){
        this.handle=row?"edit":"add"
        this.visible=true
        if(row){
          this.form=row
        }
      },
      toInit(){
        this.$refs.form&&this.$refs.form.resetFields()
      },
      getLevel(){
        if(this.form.level==5){
          this.loadSubwaryData()
        }
      },
      loadSubwaryData(){
        this.axios.get(`/api/base/system/sysDistricts/listSubways?pId=${this.form.cityId}`).then(res=>{
          this.subwayOptions = res.body
        }).catch(err=>{})
      },
      toSubmit(){
		  this.$valid("form").then(res=>{
			  let tmpArr=[0,this.form.provinceId,this.form.cityId,this.form.districtId,this.form.cityId,this.form.subwayline]
			  let postData=Object.assign({},this.form,{
			    pid:tmpArr[this.form.level]
			  })
			  this.axios.post(`/api/base/system/sysDistricts/add`,postData).then(res=>{
			    this.$notification.success({message:res.message})
			  }).catch(err=>{})
		  })
        
      },
      
    },
    created() {

    },
    
  }
</script>

<style lang="less" scoped>
</style>
