<template>
  <div>
    <a-form-model v-bind="grid.form" class="query">
      <a-row>
        <a-col v-bind="grid.col">
          <a-form-model-item label="上级">
            <PCD ref="pcd" :provinceId.sync="provinceId" :cityId.sync="cityId" :districtId.sync="districtId" :noClear="true"></PCD>
          </a-form-model-item>
        </a-col>
        <a-col v-bind="grid.col">
          <a-form-model-item label="层级" prop="level">
            <a-select v-model="level" @select="getLevel">
              <a-select-option v-if="!provinceId" :value="0">省</a-select-option>
              <a-select-option v-if="provinceId&&!cityId" :value="1">市</a-select-option>
              <a-select-option v-if="cityId&&!districtId" :value="2">区/县</a-select-option>
              <a-select-option v-if="districtId" :value="3">板块</a-select-option>
              <a-select-option v-if="cityId&&!districtId" :value="4">地铁线</a-select-option>
              <a-select-option v-if="cityId&&!districtId" :value="5">地铁站</a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col v-bind="grid.col" v-if="level==5">
          <a-form-model-item label="地铁线">
            <a-select v-model="subwayline" :options="subwayOptions"></a-select>
          </a-form-model-item>
        </a-col>
        <a-col v-bind="grid.col">
          <a-form-model-item v-bind="grid.btn">
            <a-button type="primary" @click="toSearch(0)" icon="search">查询</a-button>
            <a-button type="default" @click="toSearch(1)" icon="sync">重置</a-button>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
    <div>
      <a-button type="primary" @click="toHandler('add')">新建</a-button>
      <a-button type="primary" @click="toHandler('edit')">编辑</a-button>
    </div>
    <a-table :row-selection="rowSelection" :rowKey="record=>record.id" :columns="columns" :data-source="tableData"
      bordered :pagination="false" :customRow="changeTableRow">
      <span slot="type">{{typeName}}</span>
    </a-table>
    <!-- <a-tree :load-data="onLoadData" :tree-data="treeData" @select="select" :show-line="true" :blockNode="true" /> -->
    <PCDModal ref="PCDModal"></PCDModal>
  </div>

</template>

<script>
  import PCDModal from "./components/PCDModal.vue"
  import * as columns from "./components/columns.js"
  export default {
    data() {
      return {
        grid: this.$grid,
        provinceId:undefined,
        cityId:undefined,
        districtId:undefined,
        level: 0, 
        visible: false,
        rules: {},
        columns: columns.PCDColumns,
        subwayOptions: [],
        subwayline: undefined,
        selectedRowKeys: [],
        selectedRows: [],
        tableData: [],
        typeName:""
      };
    },
    watch:{
      provinceId(val,old){
        this.level=val?1:0
      },
      cityId(val,old){
        this.level=val?2:1
      },
      districtId(val){
        this.level=val?3:2
      },
      subwayline(val){
        this.level= 5//val?4:3
      },
    },
    created() {
      this.toSearch(0)
    },
    computed: {
      rowSelection() {
        return {
          type: "radio", //"radio"
          selectedRowKeys: this.selectedRowKeys,
          onChange: (selectedRowKeys, selectedRows) => {
            this.selectedRowKeys = selectedRowKeys
            this.selectedRows = selectedRows
          }
        }
      },
    },
    components:{PCDModal},
    methods: {
      toHandler(handle) {
        let form={
          provinceId:this.provinceId,
          cityId:this.cityId,
          districtId:this.districtId,
          level:this.level,
          subwayline:this.subwayline
        }
        if(handle=='add'){
          this.$refs.PCDModal.showInfo(form,"add")
          return;
        }
        if(this.selectedRowKeys.length==0){
          this.$notification.warning({message:"请选择一条记录"})
          return;
        }
        if(handle=='edit'){
          form.name=this.selectedRows[0].label
          form.id=this.selectedRows[0].id
          this.$refs.PCDModal.showInfo(form,"add")
        }
        
        this.$refs.PCDModal.showInfo(form)
      },
      toSearch(type) {
        this.selectedRows = []
        this.selectedRowKeys = []
        if(type==1){
          this.level=0
          this.provinceId=undefined
          this.cityId=undefined;
          this.districtId=undefined;
          this.subwayline=undefined
        }
        let tmpArr = [0, this.provinceId, this.cityId, this.districtId, this.cityId, this.subwayline]
        let api = [0, 1, 2, 3].indexOf(this.level) >= 0 ? "/api/base/system/sysDistricts/list" :
          "/api/base/system/sysDistricts/listSubways"
        let pId=tmpArr[this.level]?tmpArr[this.level]:''
        this.axios.get(`${api}?pId=${type==1?0:pId}`).then(res => {
          this.tableData = res.body
          this.typeName=["省","市","区/县","板块","地铁线","地铁站"][this.level]
        }).catch(err => {})

      },
      getLevel() {
        if (this.level == 5) {
          this.loadSubwaryData()
        }
      },
      loadSubwaryData() {
        this.axios.get(`/api/base/system/sysDistricts/listSubways?pId=${this.cityId}`).then(res => {
          this.subwayOptions = res.body
        }).catch(err => {})
      },
      changeTableRow(record, index) {
        return {
          on: {
            click: e => {
              if (this.selectedRowKeys[0] == record.id) {
                this.selectedRowKeys = []
                this.selectedRows = []
              } else {
                this.selectedRowKeys = [record.id]
                this.selectedRows = [record]
              }
            }
          }
        }
      },
    },
  };
</script>
